import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { rtdb } from "../../../services/firebaseConfig";

interface Player {
  nullifierHash: string;
  joinTime: string;
  stackSize?: number; // Optional, if some players might not have this property
}

interface GameRoom {
  id: string;
  gameId: string;
  players: Player[];
  createdAt: string;
}

interface OngoingGamesProps {
  userData?: { nullifierHash: string };
}

const OngoingGames: React.FC<OngoingGamesProps> = ({ userData }) => {
  const [ongoingGames, setOngoingGames] = useState<GameRoom[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const gameRoomsRef = ref(rtdb, "activeGames");

    const unsubscribe = onValue(
      gameRoomsRef,
      (snapshot) => {
        const games = snapshot.val();
        const activeGames: GameRoom[] = games
          ? Object.keys(games).map((id) => ({
              id,
              gameId: games[id].gameId,
              players: games[id].players.map((player: any) => ({
                nullifierHash: player.nullifierHash,
                joinTime: player.joinTime,
                stackSize: player.stackSize, // Ensure stackSize is fetched
              })) || [],
              createdAt: games[id].createdAt,
            }))
          : [];
        setOngoingGames(activeGames);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching active games:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handlePlayGame = (gameRoomId: string) => {
    navigate(`/GameRoom?roomId=${gameRoomId}`);
  };

  const handleSpectateGame = (gameRoomId: string) => {
    navigate(`/GameRoom?roomId=${gameRoomId}&spectate=true`);
  };

  return (
    <div className="ongoing-games">
      <h2 className="text-xl font-bold mb-4">Ongoing Games</h2>
      {loading ? (
        <p>Loading ongoing games...</p>
      ) : ongoingGames.length === 0 ? (
        <p>No ongoing games at the moment.</p>
      ) : (
        <ul className="space-y-4">
          {ongoingGames.map((game) => (
            <li key={game.id} className="p-4 bg-gray-100 rounded shadow">
              <p className="font-semibold">Game ID: {game.gameId}</p>
              <p>Started at: {new Date(game.createdAt).toLocaleString()}</p>
              <p>Players ({game.players.length}/2):</p>
              <ul className="ml-4 list-disc">
                {game.players.map((player) => (
                  <li key={player.nullifierHash}>
                    Player ID: {player.nullifierHash} (Joined:{" "}
                    {new Date(player.joinTime).toLocaleTimeString()})
                    {player.stackSize !== undefined && (
                      <span> - Stack Size: {player.stackSize}</span>
                    )}
                  </li>
                ))}
              </ul>
              <div className="mt-2">
                {userData &&
                game.players.some((p) => p.nullifierHash === userData.nullifierHash) ? (
                  <button
                    onClick={() => handlePlayGame(game.id)}
                    className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                  >
                    Play
                  </button>
                ) : (
                  <button
                    onClick={() => handleSpectateGame(game.id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Spectate
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OngoingGames;

/*
import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { rtdb } from "../../../services/firebaseConfig";

interface Player {
  nullifierHash: string;
  joinTime: string;
}

interface GameRoom {
  id: string;
  gameId: string;
  players: Player[];
  createdAt: string;
}

interface OngoingGamesProps {
  userData?: { nullifierHash: string };
}

const OngoingGames: React.FC<OngoingGamesProps> = ({ userData }) => {
  const [ongoingGames, setOngoingGames] = useState<GameRoom[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const gameRoomsRef = ref(rtdb, "activeGames");

    // Listen for updates in the activeGames node
    const unsubscribe = onValue(
      gameRoomsRef,
      (snapshot) => {
        const games = snapshot.val();
        const activeGames: GameRoom[] = games
          ? Object.keys(games).map((id) => ({
              id,
              gameId: games[id].gameId,
              players: games[id].players || [],
              createdAt: games[id].createdAt,
            }))
          : [];
        setOngoingGames(activeGames);
        setLoading(false); // Stop loading once data is fetched
      },
      (error) => {
        console.error("Error fetching active games:", error);
        setLoading(false); // Stop loading even if there's an error
      }
    );

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  const handlePlayGame = (gameRoomId: string) => {
    navigate(`/GameRoom?roomId=${gameRoomId}`);
  };

  const handleSpectateGame = (gameRoomId: string) => {
    navigate(`/GameRoom?roomId=${gameRoomId}&spectate=true`);
  };

  return (
    <div className="ongoing-games">
      <h2 className="text-xl font-bold mb-4">Ongoing Games</h2>
      {loading ? (
        <p>Loading ongoing games...</p>
      ) : ongoingGames.length === 0 ? (
        <p>No ongoing games at the moment.</p>
      ) : (
        <ul className="space-y-4">
          {ongoingGames.map((game) => (
            <li key={game.id} className="p-4 bg-gray-100 rounded shadow">
              <p className="font-semibold">Game ID: {game.gameId}</p>
              <p>Started at: {new Date(game.createdAt).toLocaleString()}</p>
              <p>Players ({game.players.length}/2):</p>
              <ul className="ml-4 list-disc">
                {game.players.map((player) => (
                  <li key={player.nullifierHash}>
                    Player ID: {player.nullifierHash} (Joined:{" "}
                    {new Date(player.joinTime).toLocaleTimeString()})
                  </li>
                ))}
              </ul>
              <div className="mt-2">
                {userData && game.players.some((p) => p.nullifierHash === userData.nullifierHash) ? (
                  <button
                    onClick={() => handlePlayGame(game.id)}
                    className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                  >
                    Play
                  </button>
                ) : (
                  <button
                    onClick={() => handleSpectateGame(game.id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Spectate
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OngoingGames;
*/