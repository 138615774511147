// App.tsx
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage/index";
import GameRoom from "./GameRoom/index";
import { useUser } from "../contexts/UserContext";

export default function App() {
  const { userData, loading } = useUser();

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <main className="flex min-h-screen flex-col items-center justify-center p-24 gap-y-3">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/GameRoom"
            element={userData ? <GameRoom /> : <p>Please sign in to access the game room.</p>}
          />
        </Routes>
      </main>
    </Router>
  );
}

/*
// App.tsx
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage/index";
import GameRoom from "./GameRoom/index";
import { useUser } from "../contexts/UserContext";

export default function App() {
  const { userData, loading } = useUser();

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <main className="flex min-h-screen flex-col items-center justify-center p-24 gap-y-3">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/GameRoom"
            element={userData ? <GameRoom userData={userData} /> : <p>Please sign in to access the game room.</p>}
          />
        </Routes>
      </main>
    </Router>
  );
}
*/