// frontend/src/contexts/UserContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { ref, get, onValue } from "firebase/database";
import { auth, rtdb } from "../services/firebaseConfig";

export interface UserData {
  walletBalance: number;
  nullifierHash: string;
}

interface UserContextType {
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  loading: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const nullifierHash = user.uid; // Assuming `uid` is the nullifierHash
        const userRef = ref(rtdb, `users/${nullifierHash}`);

        get(userRef).then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setUserData({ walletBalance: data.walletBalance, nullifierHash });
          }
        });

        const walletBalanceRef = ref(rtdb, `users/${nullifierHash}/walletBalance`);
        onValue(walletBalanceRef, (snapshot) => {
          setUserData((prev) => prev ? { ...prev, walletBalance: snapshot.val() } : prev);
        });
      } else {
        setUserData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ userData, setUserData, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
