// main.tsx
import { createRoot } from "react-dom/client";
import App from "./pages/App";
import "./index.css";
import MiniKitProvider from "./minikit-provider";
import { StrictMode } from "react";
import { ErudaProvider } from "./components/Eruda";
import { UserProvider } from "./contexts/UserContext"; // Import the new UserProvider

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErudaProvider>
      <MiniKitProvider>
        <UserProvider> {/* Wrap the app with UserProvider */}
          <App />
        </UserProvider>
      </MiniKitProvider>
    </ErudaProvider>
  </StrictMode>
);
