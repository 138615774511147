import { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { rtdb } from '../../services/firebaseConfig';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import './styles/GameRoom.css';

const ROUND_TIMER = 20;

type GameDetails = {
  gameStatus: string;
  players: Array<{ nullifierHash: string; stackSize: number }>;
  winner?: string;
  countdown?: number;
};
  
const GameRoom = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gameRoomId = queryParams.get('roomId');
  const { userData } = useUser();
  const [gameDetails, setGameDetails] = useState<GameDetails | null>(null);
  const [timer, setTimer] = useState<number>(ROUND_TIMER);
  const [roundStatus, setRoundStatus] = useState<string>('');
  const [gameEnded, setGameEnded] = useState(false);

  useEffect(() => {
    if (!gameRoomId) {
      console.error('No gameRoomId provided!');
      return;
    }

    const gameRef = ref(rtdb, `activeGames/${gameRoomId}`);

    // Fetch initial game details and listen for changes
    const unsubscribe = onValue(gameRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGameDetails(data);
        if (data.gameStatus === 'completed') {
          setGameEnded(true);
          setRoundStatus(`Game Over. Winner: ${data.winner || 'No one'}`);
        } else if (data.gameStatus === 'starting') {
          setRoundStatus(`Game starting in ${data.countdown} seconds...`);
          setTimer(data.countdown);
        } else if (data.gameStatus === 'in-progress') {
          setRoundStatus('Game in progress. Make your move!');
        }
      }
    });

    return () => unsubscribe();
  }, [gameRoomId]);

  useEffect(() => {
    if (timer <= 0 || !gameDetails || gameDetails.gameStatus !== 'in-progress') return;

    const interval = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer, gameDetails]);

  const handleChoiceSubmit = async (choice: string | null) => {
    const currentPlayerId = userData?.nullifierHash;
    if (!currentPlayerId) return;
  
    // Send the player's choice to the backend to update the database and trigger game logic
    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/gameRoom/manage-round`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          gameRoomId,
          playerId: currentPlayerId,
          choice: choice,  // Send the choice (rock, paper, or scissors)
        }),
      });
  
      const result = await response.json();
      if (result.status === 'success') {
        setRoundStatus('Waiting for results...');
      } else {
        console.error('Error submitting choice:', result.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleStartGame = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/gameRoom/direct-start`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          gameRoomId,
          playerId: userData?.nullifierHash,
        }),
      });

      const result = await response.json();
      if (result.status === 'success') {
        setRoundStatus('Game started!');
      } else {
        console.error('Error starting game:', result.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderChoices = () => (
    <div className="choicesContainer">
      <button className="choiceButton" onClick={() => handleChoiceSubmit('rock')}>Rock</button>
      <button className="choiceButton" onClick={() => handleChoiceSubmit('paper')}>Paper</button>
      <button className="choiceButton" onClick={() => handleChoiceSubmit('scissors')}>Scissors</button>
    </div>
  );

  const getOpponent = () => {
    return gameDetails?.players.find((p) => p.nullifierHash !== userData?.nullifierHash);
  };

  const opponent = getOpponent();

  return (
    <div className="container">
      {gameRoomId ? (
        gameDetails ? (
          <div className="gameRoomContainer">
            <h2>Game Room: {gameRoomId}</h2>
            <div className="playerInfo">
              <h3>Opponent's Stack Size: {opponent?.stackSize}</h3>
              <div>Player: {opponent?.nullifierHash}</div>
            </div>
            <div>{gameEnded ? roundStatus : `Timer: ${timer}s`}</div>
            {gameDetails.gameStatus === 'in-progress' ? renderChoices() : (
              <button className="startButton" onClick={handleStartGame}>Start Game</button>
            )}
            <div className="playerInfo">
              <h3>Your Stack Size: {gameDetails.players[0].stackSize}</h3>
              <div>Player: {userData?.nullifierHash}</div>
            </div>
          </div>
        ) : (
          <div>Loading game details...</div>
        )
      ) : (
        <div>Error: No Game Room ID provided!</div>
      )}
    </div>
  );
};

export default GameRoom;

/*
import React, { useEffect, useState } from 'react';
import { ref, get, onValue, update } from 'firebase/database';
import { rtdb } from '../../services/firebaseConfig';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

const ROUND_TIMER = 20;

const GameRoom = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gameRoomId = queryParams.get('roomId');

  const { userData } = useUser();
  const [gameDetails, setGameDetails] = useState<any>(null);
  const [currentPlayerChoice, setCurrentPlayerChoice] = useState<string | null>(null);
  const [timer, setTimer] = useState<number>(ROUND_TIMER);
  const [roundStatus, setRoundStatus] = useState<string>('');
  const [gameEnded, setGameEnded] = useState(false);

  useEffect(() => {
    if (!gameRoomId) {
      console.error('No gameRoomId provided!');
      return;
    }

    const gameRef = ref(rtdb, `activeGames/${gameRoomId}`);

    // Fetch initial game details and listen for changes
    const unsubscribe = onValue(gameRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGameDetails(data);
        if (data.gameStatus === 'completed') {
          setGameEnded(true);
          setRoundStatus(`Game Over. Winner: ${data.winner || 'No one'}`);
        } else if (data.gameStatus === 'starting') {
          setRoundStatus(`Game starting in ${data.countdown} seconds...`);
          setTimer(data.countdown);
        } else if (data.gameStatus === 'in-progress') {
          setRoundStatus('Game in progress. Make your move!');
        }
      }
    });

    return () => unsubscribe();
  }, [gameRoomId]);

  useEffect(() => {
    if (timer <= 0 || !gameDetails || gameDetails.gameStatus !== 'in-progress') return;

    const interval = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer, gameDetails]);

  const handleChoiceSubmit = async (choice: string | null) => {
    const currentPlayerId = userData?.nullifierHash;
    if (!currentPlayerId) return;
  
    // Send the player's choice to the backend to update the database and trigger game logic
    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/gameRoom/manage-round`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          gameRoomId,
          playerId: currentPlayerId,
          choice: choice,  // Send the choice (rock, paper, or scissors)
        }),
      });
  
      const result = await response.json();
      if (result.status === 'success') {
        setCurrentPlayerChoice(choice);
        setRoundStatus('Waiting for results...');
      } else {
        console.error('Error submitting choice:', result.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleStartGame = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/gameRoom/direct-start`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          gameRoomId,
          playerId: userData?.nullifierHash,
        }),
      });

      const result = await response.json();
      if (result.status === 'success') {
        setRoundStatus('Game started!');
      } else {
        console.error('Error starting game:', result.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderChoices = () => (
    <div style={styles.choicesContainer}>
      <button style={styles.choiceButton} onClick={() => handleChoiceSubmit('rock')}>Rock</button>
      <button style={styles.choiceButton} onClick={() => handleChoiceSubmit('paper')}>Paper</button>
      <button style={styles.choiceButton} onClick={() => handleChoiceSubmit('scissors')}>Scissors</button>
    </div>
  );

  const getOpponent = () => {
    return gameDetails?.players.find((p: any) => p.nullifierHash !== userData?.nullifierHash);
  };

  const opponent = getOpponent();

  return (
    <div style={styles.container}>
      {gameRoomId ? (
        gameDetails ? (
          <div style={styles.gameRoomContainer}>
            <h2>Game Room: {gameRoomId}</h2>
            <div style={styles.playerInfo}>
              <h3>Opponent's Stack Size: {opponent?.stackSize}</h3>
              <div>Player: {opponent?.nullifierHash}</div>
            </div>
            <div>{gameEnded ? roundStatus : `Timer: ${timer}s`}</div>
            {gameDetails.gameStatus === 'in-progress' ? renderChoices() : (
              <button style={styles.startButton} onClick={handleStartGame}>Start Game</button>
            )}
            <div style={styles.playerInfo}>
              <h3>Your Stack Size: {gameDetails.players[0].stackSize}</h3>
              <div>Player: {userData?.nullifierHash}</div>
            </div>
          </div>
        ) : (
          <div>Loading game details...</div>
        )
      ) : (
        <div>Error: No Game Room ID provided!</div>
      )}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  gameRoomContainer: {
    border: '2px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
  },
  playerInfo: {
    margin: '10px 0',
  },
  choicesContainer: {
    marginTop: '20px',
  },
  choiceButton: {
    padding: '10px 20px',
    margin: '5px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  },
  startButton: {
    padding: '10px 20px',
    marginTop: '20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#2196F3',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default GameRoom;
*/