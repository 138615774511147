// Entry point for the app, redirects to login or homepage
// pages/HomePage/index.tsx
"use client";

import { useState, useEffect, useCallback } from "react";
import { rtdb } from "../../services/firebaseConfig";
import { ref, onValue, get, set } from "firebase/database";
import { getAuth, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import WorldIDSignIn from "./components/WorldIDSignIn";
import Wallet from "./components/Wallet";
import GameList from "./components/GameList";
import OngoingGames from "./components/OngoingGames";

interface GamePlayer {
  nullifierHash: string;
}

interface GameData {
  players: GamePlayer[];
}

export default function HomePage() {
  const { userData, loading, setUserData } = useUser();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();

  const fetchUserData = useCallback(
    async (nullifierHash: string) => {
      try {
        const userRef = ref(rtdb, `users/${nullifierHash}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userInfo = { ...userSnapshot.val(), nullifierHash };
          setUserData(userInfo);
        } else {
          const defaultData = { walletBalance: 0 };
          await set(userRef, defaultData);
          setUserData({ ...defaultData, nullifierHash });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    [setUserData]
  );

  const handleVerification = async (nullifierHash: string, worldIDResponse: object) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/auth/world-id`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ nullifierHash, worldIDResponse }),
      });

      if (!response.ok) throw new Error("Failed to verify user with backend.");

      const { token } = await response.json();

      const auth = getAuth();
      await signInWithCustomToken(auth, token);
    } catch (error) {
      console.error("Error during verification and Firebase Auth:", error);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsSignedIn(true);
        if (!userData) {
          const nullifierHash = user.uid;
          await fetchUserData(nullifierHash);
        }
      } else {
        setIsSignedIn(false);
      }
    });

    return () => unsubscribe();
  }, [userData, fetchUserData]);

  // ** Real-Time Listener for activeGames **
  useEffect(() => {
    if (!userData) return;

    const activeGamesRef = ref(rtdb, "activeGames");

    const unsubscribe = onValue(activeGamesRef, (snapshot) => {
      if (!snapshot.exists()) return;

      const activeGames = snapshot.val();

      for (const [gameRoomId, gameDataRaw] of Object.entries(activeGames)) {
        const gameData = gameDataRaw as GameData; // Cast to GameData type

        if (
          gameData.players.some(
            (player) => player.nullifierHash === userData.nullifierHash
          )
        ) {
          navigate(`/GameRoom?roomId=${gameRoomId}`);
          break;
        }
      }
    });

    return () => unsubscribe();
  }, [userData, navigate]);

  if (loading) return <p>Loading...</p>;

  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-24 gap-y-3">
      {!isSignedIn ? (
        <WorldIDSignIn onVerified={handleVerification} />
      ) : userData ? (
        <>
          <Wallet userData={userData} />
          <OngoingGames userData={userData} />
          <GameList userData={userData} />
        </>
      ) : (
        <p>Loading user data...</p>
      )}
    </main>
  );
}

/*
// Entry point for the app, redirects to login or homepage
// pages/HomePage/index.tsx
"use client";
import { useState, useEffect, useCallback } from "react";
import { rtdb } from "../../services/firebaseConfig";
import { ref, onValue, get, set } from "firebase/database";
import { getAuth, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import WorldIDSignIn from "./components/WorldIDSignIn";
import Wallet from "./components/Wallet";
import GameList from "./components/GameList";
import OngoingGames from "./components/OngoingGames";

export default function HomePage() {
  const { userData, loading, setUserData } = useUser();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();

  const fetchUserData = useCallback(
    async (nullifierHash: string) => {
      try {
        const userRef = ref(rtdb, `users/${nullifierHash}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userInfo = { ...userSnapshot.val(), nullifierHash };
          setUserData(userInfo);
        } else {
          const defaultData = { walletBalance: 0 };
          await set(userRef, defaultData);
          setUserData({ ...defaultData, nullifierHash });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    [setUserData]
  );

  const handleVerification = async (nullifierHash: string, worldIDResponse: object) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/auth/world-id`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ nullifierHash, worldIDResponse }),
      });

      if (!response.ok) throw new Error("Failed to verify user with backend.");

      const { token } = await response.json();

      const auth = getAuth();
      await signInWithCustomToken(auth, token);
    } catch (error) {
      console.error("Error during verification and Firebase Auth:", error);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsSignedIn(true);
        if (!userData) {
          const nullifierHash = user.uid;
          await fetchUserData(nullifierHash);
        }
      } else {
        setIsSignedIn(false);
      }
    });

    return () => unsubscribe();
  }, [userData, fetchUserData]);

  // ** Real-Time Listener for activeGames **
  useEffect(() => {
    if (!userData) return;

    const activeGamesRef = ref(rtdb, "activeGames");

    const unsubscribe = onValue(activeGamesRef, (snapshot) => {
      if (!snapshot.exists()) return;

      const activeGames = snapshot.val();

      for (const [gameRoomId, gameData] of Object.entries(activeGames)) {
        if (
          gameData.players.some(
            (player: { nullifierHash: string }) => player.nullifierHash === userData.nullifierHash
          )
        ) {
          navigate(`/GameRoom?roomId=${gameRoomId}`);
          // navigate(`/GameRoom/${gameRoomId}`);
          break;
        }
      }
    });

    return () => unsubscribe();
  }, [userData, navigate]);

  if (loading) return <p>Loading...</p>;

  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-24 gap-y-3">
      {!isSignedIn ? (
        <WorldIDSignIn onVerified={handleVerification} />
      ) : userData ? (
        <>
          <Wallet userData={userData} />
          <OngoingGames userData={userData} />
          <GameList userData={userData} />
        </>
      ) : (
        <p>Loading user data...</p>
      )}
    </main>
  );
}
*/